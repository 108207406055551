import React from "react";

import { getPlayerName } from "../models/game";

export default function Players({
  players,
  currentPlayer,
  gameStarted,
  users,
}) {
  const playerList = gameStarted ? players : users;

  return (
    <div className="c-players">
      <table className="c-players__table">
        <thead>
          <tr>
            <td>players</td>
            {gameStarted && (
              <>
                <td>bid</td>
                <td>hands</td>
                <td>score</td>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {playerList.map((player, index) => (
            <tr key={player.id}>
              <td className="c-player__name">
                <div className="u-truncate">
                  {getPlayerName(users, player.id)}
                </div>
                {currentPlayer === index ? (
                  <span className="c-player__turn"></span>
                ) : null}
              </td>
              {gameStarted && (
                <>
                  <td>{player.bid}</td>
                  <td>{player.hands}</td>
                  <td>{player.score}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
