import React from "react";
import { Router } from "@reach/router";

import Rooms from "../components/rooms";
import Room from "../components/room";
import PrivateRoute from "../components/privateRoute";

const App = () => {
  return (
    <Router basepath="/app">
      <PrivateRoute as={Rooms} path="/" />
      <PrivateRoute as={Room} path="/room/:roomId" />
    </Router>
  );
};

export default App;
