import IDeck from "./deck";

const getPlayerSchema = id => {
  return {
    id,
    bid: null,
    hands: null,
    score: 0,
    strikes: 0,
    cards: [],
    continue: false,
  };
};

export const getGameSchema = (roomId, playersIds, user) => {
  const players = playersIds.map(playerId => {
    return getPlayerSchema(playerId);
  });
  return {
    roomId,
    gameStarted: false,
    players: players,
    currentRound: null,
    currentPlayer: null,
    currentAction: null,
    gameHands: [],
    droppedCards: [],
    winners: [],
    atu: "",
    _user: user,
  };
};

export const gameConverter = {
  toFirestore: gameData => {
    return {
      roomId: gameData.roomId,
      gameStarted: gameData.gameStarted,
      players: gameData.players,
      currentRound: gameData.currentRound,
      currentPlayer: gameData.currentPlayer,
      currentAction: gameData.currentAction,
      gameHands: gameData.gameHands,
      droppedCards: gameData.droppedCards,
      winners: gameData.winners,
      atu: gameData.atu,
    };
  },
};

export const getClientPlayerIndex = (players, currentUser) => {
  if (players) {
    const playerIndex = players.findIndex(
      player => player.id === currentUser.uid
    );

    if (playerIndex >= 0) {
      return playerIndex;
    }

  }

  return null;
};

export const getPlayerName = (users, playerId) =>
  users.find(user => user.id === playerId)?.username;

export default class IGame {
  constructor(gameData) {
    this.roomId = gameData.roomId;
    this.gameStarted = gameData.gameStarted;
    this.players = gameData.players;
    this.currentRound = gameData.currentRound;
    this.currentPlayer = gameData.currentPlayer;
    this.currentAction = gameData.currentAction;
    this.gameHands = gameData.gameHands;
    this.droppedCards = gameData.droppedCards;
    this.winners = gameData.winners;
    this.atu = gameData.atu;
    this._user = gameData._user;

    this._clientPlayerIndex = getClientPlayerIndex(
      gameData.players,
      gameData._user
    );
  }

  startGame() {
    this.gameStarted = true;
    this._generateGameHands();
    this._newRound();
    return this;
  }

  handlePing() {
    return this;
  }

  handlePlayerAction(value) {
    switch (this.currentAction) {
      case "bid":
        this._handleBid(value);
        break;
      case "drop":
        this._handleDrop(value);
        break;
      case "continue":
        this._handleContinue(value);
        break;
      default:
        break;
    }
    return this;
  }

  _generateGameHands() {
    let rounds = [];
    // Games 1
    for (let i = 1; i <= this.players.length; i++) {
      rounds = [...rounds, 1];
    }
    // Games 2 - 7
    for (let i = 2; i <= 7; i++) {
      rounds = [...rounds, i];
    }
    // Games 8
    for (let i = 1; i <= this.players.length; i++) {
      rounds = [...rounds, 8];
    }
    // Games 7 - 2
    for (let i = 7; i >= 2; i--) {
      rounds = [...rounds, i];
    }
    // Games 1
    for (let i = 1; i <= this.players.length; i++) {
      rounds = [...rounds, 1];
    }
    this.gameHands = rounds;
  }

  _dealCards() {
    // TODO to improve Deck
    // save its instance in the constructor
    // then use shuffle
    const deck = new IDeck(this.players.length);
    const cards = deck.getCardsForGameHand(this.gameHands[this.currentRound]);

    for (let i = 0; i < this.players.length; i++) {
      this.players[i].cards = cards.players[i];
    }

    this.atu = cards.atu;
  }

  _nextPlayer() {
    this.currentPlayer = (this.currentPlayer + 1) % this.players.length;
  }

  _setActionBid() {
    this.currentAction = "bid";
  }

  _setActionDrop() {
    this.currentAction = "drop";
  }

  _setActionContinue() {
    this.currentAction = "continue";
  }

  /**
   * round index 0 -> player index 2
   * round index 1 -> player index 0
   * round index 2 -> player index 1
   * round index 3 -> player index 2
   *
   * @returns {boolean}
   * @private
   */
  _isBidOver() {
    const roundLastPlayer =
      (this.currentRound + this.players.length - 1) % this.players.length;
    return this.currentPlayer === roundLastPlayer;
  }

  _isHandOver() {
    return this.droppedCards.length === this.players.length;
  }

  _isContinueReady() {
    return this.players.every(player => player.continue === true);
  }

  _isRoundOver() {
    return this.players.every(player => player.cards.length === 0);
  }

  _isLastRound() {
    return this.currentRound === this.gameHands.length - 1;
  }

  /**
   * Increase hands number for player who won hand
   */
  _setHandWinner() {
    const droppedCardsSorted = IDeck.sortCards(this.droppedCards);
    let winnerCard;

    if (this.atu) {
      // check if there is a card with the same suite as atu
      winnerCard = droppedCardsSorted.find(card => {
        return card.name.slice(0, 1) === this.atu.slice(0, 1);
      });
    }

    if (!winnerCard) {
      // no card with the same suit as atu was found
      // find the highest card with the same suite as the first dropped card
      winnerCard = droppedCardsSorted.find(card => {
        return card.name.slice(0, 1) === this.droppedCards[0].name.slice(0, 1);
      });
    }

    this.players[winnerCard.playerIndex].hands += 1;
    this.currentPlayer = winnerCard.playerIndex;
  }

  _setBid(value) {
    this.players[this.currentPlayer].bid = value;
  }

  _setContinue(playerIndex) {
    this.players[playerIndex].continue = true;
  }

  _setDroppedCard(cardIndex) {
    this.droppedCards = [
      ...this.droppedCards,
      {
        name: this.players[this.currentPlayer].cards[cardIndex],
        playerIndex: this.currentPlayer,
      },
    ];
  }

  _removePlayerDroppedCard(cardIndex) {
    this.players[this.currentPlayer].cards = [
      ...this.players[this.currentPlayer].cards.slice(0, cardIndex),
      ...this.players[this.currentPlayer].cards.slice(cardIndex + 1),
    ];
  }

  _resetDroppedCards() {
    this.droppedCards = [];
  }

  _resetContinue() {
    this.players.forEach((player, index) => {
      this.players[index].continue = false;
    });
  }

  _resetPlayersActions() {
    this.players.forEach((player, index) => {
      this.players[index].bid = null;
      this.players[index].hands = null;
    });
  }

  _areAllPlayersWrong() {
    return this.players.every(player => {
      const hands = player.hands || 0;
      return hands !== player.bid;
    });
  }

  _updatePlayersStats() {
    this.players.forEach((player, index) => {
      if (player.hands === null) {
        player.hands = 0;
      }

      if (player.bid === player.hands) {
        this.players[index].score += 5 + player.bid;

        if (this.gameHands[this.currentRound] !== 1) {
          if (player.strikes === 4) {
            this.players[index].score += 5;
            this.players[index].strikes = 0;
          } else if (player.strikes > 0 && player.strikes < 4) {
            this.players[index].strikes += 1;
          } else {
            this.players[index].strikes = 1;
          }
        }
      } else {
        this.players[index].score -= Math.abs(player.bid - player.hands);

        if (this.gameHands[this.currentRound] !== 1) {
          if (Math.abs(player.strikes) === 4) {
            this.players[index].score -= 5;
            this.players[index].strikes = 0;
          } else if (player.strikes < 0 && player.strikes > -4) {
            this.players[index].strikes -= 1;
          } else {
            this.players[index].strikes = -1;
          }
        }
      }
    });
  }

  _setWinners() {
    const maxScore = Math.max(...this.players.map(player => player.score));

    this.winners = this.players.reduce((winners, player, index) => {
      return player.score === maxScore ? [...winners, index] : [...winners];
    }, []);
  }

  /**
   * round index 0 -> player index 0
   * round index 1 -> player index 1
   * round index 2 -> player index 2
   * round index 3 -> player index 0
   * @private
   */
  _setRoundStartingPlayer() {
    this.currentPlayer = this.currentRound % this.players.length;
  }

  _newRound() {
    if (this.currentRound == null) {
      this.currentRound = 0;
    } else {
      this.currentRound += 1;
    }
    this._resetPlayersActions();
    this._setActionBid();
    this._setRoundStartingPlayer();
    this._dealCards();
  }

  _resetRound() {
    this._resetPlayersActions();
    this._setActionBid();
    this._setRoundStartingPlayer();
    this._dealCards();
  }

  /**
   * @param playerIndex
   * @private
   */
  _handleContinue(playerIndex) {
    this._setContinue(playerIndex);

    if (this._isContinueReady()) {
      this._resetDroppedCards();
      this._resetContinue();

      if (this._isRoundOver()) {
        if (this._areAllPlayersWrong()) {
          this._resetRound();
        } else {
          this._updatePlayersStats();

          if (this._isLastRound()) {
            this._setWinners();
            this.currentAction = "finished";
          } else {
            this._newRound();
          }
        }
      } else {
        this._setActionDrop();
      }
    }
  }

  /**
   * @param index
   * @private
   */
  _handleDrop(index) {
    this._setDroppedCard(index);
    this._removePlayerDroppedCard(index);

    if (this._isHandOver()) {
      this._setHandWinner();
      this._setActionContinue();
    } else {
      this._nextPlayer();
    }
  }

  _handleBid(value) {
    this._setBid(value);

    if (this._isBidOver()) {
      this._setActionDrop();
    }

    this._nextPlayer();
  }
}
