import React, { useContext, useEffect, useRef, useState } from "react";

import firebase, { db } from "../firebase";
import IGame, { getGameSchema, gameConverter } from "../models/game";
import UserContext from "../context/auth";
import Game from "./game";
import SEO from "./seo";
import { Link } from "gatsby";
import IconBack from "../images/back.inline.svg";
import IconMore from "../images/more.inline.svg";
import { useClickOutside } from "../utils/hooks";
import { navigate } from "@reach/router";

const Room = ({ roomId }) => {
  const [room, setRoom] = useState({});
  const [usersIds, setUsersIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [popperState, setPopperState] = useState("CLOSED");
  const { user } = useContext(UserContext);

  const popperRef = useRef(null);
  useClickOutside(popperRef, popperState, setPopperState);

  // add player to room
  useEffect(() => {
    const roomDocRef = db.collection("rooms").doc(roomId);

    db.runTransaction(transaction => {
      return transaction
        .get(roomDocRef)
        .then(roomDoc => {
          const room = roomDoc.data();

          if (room.usersIds?.includes(user.uid)) {
            return;
          }

          if (room.usersIds?.length >= 6) {
            setMessage("This room is full");
            return;
          }

          if (room.game?.gameStarted === true) {
            setMessage("The game has already started");
            return;
          }

          transaction.update(roomDocRef, {
            usersIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  }, [roomId, user]);

  // get room data
  useEffect(() => {
    const unsubscribe = db
      .collection("rooms")
      .doc(roomId)
      .onSnapshot(roomDoc => {
        if (roomDoc.exists) {
          const roomData = roomDoc.data();

          if (roomData.usersIds?.includes(user.uid)) {
            const game = new IGame({ ...roomData.game, _user: user });
            const newRoom = { ...roomData, game: game };
            setRoom(newRoom);
            if (newRoom.usersIds.toString() !== usersIds.toString()) {
              setUsersIds(newRoom.usersIds);
            }
          } else {
            setRoom(roomData);
          }
        } else {
          setMessage("This room does not exist");
        }
      });

    return () => {
      unsubscribe();
    };
  }, [roomId, user, usersIds]);

  // get users data
  useEffect(() => {
    if (usersIds.length) {
      const usersDocs = usersIds.map(userId =>
        db
          .collection("users")
          .doc(userId)
          .get()
      );
      Promise.all(usersDocs).then(docs => {
        setUsers(
          docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          })
        );
      });
    }
  }, [usersIds]);

  const handleStartGame = () => {
    const roomDocRef = db.collection("rooms").doc(roomId);
    db.runTransaction(transaction => {
      return transaction
        .get(roomDocRef)
        .then(roomDoc => {
          const gameManager = new IGame(
            getGameSchema(roomId, room.usersIds, user)
          );
          const gameData = gameManager.startGame();

          transaction.update(roomDocRef, {
            game: gameConverter.toFirestore(gameData),
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const handleContinue = () => {
    const roomDocRef = db.collection("rooms").doc(roomId);
    db.runTransaction(transaction => {
      return transaction
        .get(roomDocRef)
        .then(roomDoc => {
          const roomData = roomDoc.data();
          const game = new IGame({ ...roomData.game, _user: user });
          const gameData = game.handlePlayerAction(
            room.game._clientPlayerIndex
          );

          if (!roomDoc.exists) {
            throw new Error("Document does not exist!");
          }
          transaction.update(roomDocRef, {
            game: gameConverter.toFirestore(gameData),
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const handleBid = value => {
    const gameData = room.game.handlePlayerAction(value);
    db.collection("rooms")
      .doc(roomId)
      .update({
        game: gameConverter.toFirestore(gameData),
      });
  };

  const handleDrop = value => {
    const gameData = room.game.handlePlayerAction(value);
    db.collection("rooms")
      .doc(roomId)
      .update({
        game: gameConverter.toFirestore(gameData),
      });
  };

  const handleClickLeaveRoom = () => {
    const roomDocRef = db.collection("rooms").doc(roomId);

    db.runTransaction(transaction => {
      return transaction
        .get(roomDocRef)
        .then(roomDoc => {
          const room = roomDoc.data();
          const newUsersIds = room.usersIds.filter(item => item !== user.uid);

          transaction.update(roomDocRef, {
            usersIds: newUsersIds,
            game: null,
          });
        })
        .then(() => {
          navigate(`/app`);
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const handleClickRestart = () => {
    setPopperState("CLOSED");
    setRoom({});
    handleStartGame();
  };

  return (
    <div className="o-page">
      <SEO title={`${room.name}`} />

      <header className="c-app-header">
        <div className="c-app-header__action">
          <Link
            to={`/app`}
            className="c-app-header__button c-app-header__button--back"
          >
            <IconBack className="c-app-header__button-icon" />
          </Link>
        </div>
        <div className="c-app-header__main u-text-center">{room.name}</div>
        <div className="c-app-header__action">
          {room?.usersIds?.includes(user.uid) && (
            <>
              <button
                className="c-app-header__button c-app-header__button--menu"
                onClick={() => setPopperState("OPEN")}
              >
                <IconMore className="c-app-header__button-icon" />
              </button>
              <div
                className={`c-app-header__menu ${
                  popperState === "OPEN" ? "is-open" : ""
                }`}
                ref={popperRef}
              >
                <ul className="o-list-vertical o-list-vertical--0">
                  <li className="o-list-vertical__item">
                    <button
                      onClick={handleClickRestart}
                      className="c-menu__link"
                    >
                      Restart game
                    </button>
                  </li>
                  <li className="o-list-vertical__item">
                    <button
                      onClick={handleClickLeaveRoom}
                      className="c-menu__link"
                    >
                      Leave room
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </header>

      {room?.usersIds?.includes(user.uid) && (
        <Game
          game={room.game}
          users={users}
          onContinue={handleContinue}
          onBid={handleBid}
          onDrop={handleDrop}
          onStartGame={handleStartGame}
          onRestartGame={handleStartGame}
        />
      )}

      {!room?.usersIds?.includes(user.uid) && message && (
        <>
          <div className="p-room">
            <div className="p-room__message">
              <p>{message}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Room;
