import { useEffect } from "react";

export function useClickOutside(ref, state, setState) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setState("CLOSED");
      }
    }
    // Bind the event listener
    if (state === "OPEN") {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, state, setState]);
}
