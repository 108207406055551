export const cardsWeights = {
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  J: 11,
  Q: 12,
  K: 13,
  A: 14,
};

export const cardsNames = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
  "A",
];

export const cardsSuits = ["H", "D", "S", "C"];

export default class IDeck {
  constructor(playersNumber) {
    this.playersNumber = playersNumber;
    this.cards = [];

    const startingFrom = (52 - this.playersNumber * 8) / 4;

    for (let s = 0; s < cardsSuits.length; s++) {
      for (let n = startingFrom; n < cardsNames.length; n++) {
        this.cards.push(`${cardsSuits[s]}${cardsNames[n]}`);
      }
    }
  }

  static sortCards(cards) {
    const mapped = cards.map(card => {
      return { ...card, weight: cardsWeights[card.name.slice(1)] };
    });
    const newMapped = mapped.sort((a, b) => {
      return b.weight - a.weight;
    });

    return newMapped;
  }

  getCardsForGameHand(gameHand) {
    let cards = [];
    let shuffled = this.shuffle(this.cards);

    let start,
      end = 0;

    for (let i = 0; i < this.playersNumber; i++) {
      start = end;
      end = start + gameHand;

      const playerCards = shuffled.slice(start, end);
      const playerCardsSortedAndGrouped = this._sortAndGroupSimpleCards(
        playerCards
      );

      cards.push(playerCardsSortedAndGrouped);
    }

    let atu = gameHand !== 8 ? shuffled[end] : null;

    return {
      players: cards,
      atu: atu,
    };
  }

  // https://bost.ocks.org/mike/shuffle/
  shuffle(array) {
    let m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  _sortAndGroupSimpleCards(cards) {
    const cardsGrouped = {};

    cards.forEach(card => {
      const cardSuit = card.slice(0, 1);
      if (cardsGrouped.hasOwnProperty(cardSuit)) {
        cardsGrouped[cardSuit].push(card);
      } else {
        cardsGrouped[cardSuit] = [card];
      }
    });

    const sortedCards = [];

    Object.keys(cardsGrouped)
      .sort((a, b) => {
        return b - a;
      })
      .forEach(key => {
        sortedCards.push(
          ...cardsGrouped[key]
            .map(card => {
              return { name: card, weight: cardsWeights[card.slice(1)] };
            })
            .sort((a, b) => {
              return b.weight - a.weight;
            })
            .map(card => card.name)
        );
      });

    return sortedCards;
  }
}
