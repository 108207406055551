import React, { useState } from "react";

const copyToClipboard = () => {
  const el = document.createElement("textarea");
  el.value = window.location.href;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

function StartGame({ onStartGame }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    copyToClipboard();

    setCopied(() => {
      setTimeout(() => {
        setCopied(false);
      }, 1500);

      return true;
    });
  };

  return (
    <div className="u-ph u-pv">
      <div className="c-room-link">
        <div className={`c-room-link__success ${copied ? "is-active" : ""}`}>
          copied
        </div>
        <button
          type="button"
          onClick={handleCopy}
          className="c-button c-button--red c-button--large u-1/1 u-mb c-room-link__button"
        >
          Copy room link
        </button>
      </div>
      <button
        type="button"
        onClick={onStartGame}
        className="c-button c-button--large u-1/1"
      >
        Start game
      </button>
    </div>
  );
}

export default StartGame;
