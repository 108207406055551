import React from "react";

import RoomInfo from "./roomInfo";
import Players from "./players";
import Stats from "./stats";
import Continue from "./continue";
import Atu from "./atu";
import Bid from "./bid";
import Dropped from "./dropped";
import StartGame from "./startGame";
import RestartGame from "./restartGame";
import Cards from "./cards";
import Finished from "./finished";

import { getPlayerName } from "../models/game";

const Game = ({
  game,
  users,
  onContinue,
  onBid,
  onDrop,
  onStartGame,
  onRestartGame,
}) => {
  const playerTurnName = game?.gameStarted
    ? getPlayerName(users, game?.players[game?.currentPlayer].id)
    : null;

  return (
    <>
      <div className="c-game">
        <div className="c-game__top">
          <Stats game={game} />
          <Players
            users={users}
            players={game?.players}
            currentPlayer={game?.currentPlayer}
            gameStarted={game?.gameStarted}
          />
        </div>
        <div className="c-game__middle">
          {game?.gameStarted && game.currentAction !== "finished" && (
            <div className="c-middle">
              <Atu atu={game?.atu} />

              {game.currentAction === "bid" && (
                <Bid onBid={onBid} game={game} />
              )}

              {game.currentAction !== "bid" && (
                <Dropped
                  cards={game.droppedCards}
                  players={game.players}
                  users={users}
                />
              )}
            </div>
          )}

          {game?.gameStarted && game.currentAction === "finished" && (
            <Finished
              winnersIndexes={game.winners}
              users={users}
              players={game?.players}
            />
          )}

          {game && !game.gameStarted && <RoomInfo />}
        </div>

        <div
          className={`c-game__bottom ${
            game?.gameStarted && game.currentAction !== "finished"
              ? "with-cards"
              : ""
          }`}
        >
          {game && !game.gameStarted && <StartGame onStartGame={onStartGame} />}

          {game?.gameStarted && game.currentAction === "finished" && (
            <RestartGame onRestartGame={onRestartGame} />
          )}

          {game?.gameStarted && game.currentAction !== "finished" && (
            <Cards
              cards={game.players[game._clientPlayerIndex].cards}
              atu={game.atu}
              firstDrop={game.droppedCards[0]}
              onDrop={onDrop}
              hisTurn={game._clientPlayerIndex === game.currentPlayer}
              currentAction={game.currentAction}
            />
          )}
        </div>
        <footer className="c-game__footer">
          {game?.currentAction === "continue" && (
            <Continue users={users} game={game} onContinue={onContinue} />
          )}

          {game?.currentAction !== "continue" && game?.gameStarted && (
            <>
              <div className="c-game__footer-message">
                {playerTurnName && game?.currentAction !== "finished"
                  ? `Waiting for ${playerTurnName} to ${game?.currentAction}`
                  : <>&nbsp;</>}
              </div>
              <div className="c-game__footer-button-container"></div>
            </>
          )}
        </footer>
      </div>
    </>
  );
};

export default Game;
