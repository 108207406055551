import React, { useState } from "react";

const Bid = ({ game, onBid }) => {
  const [selected, setSelected] = useState(null);
  const [bid, setBid] = useState(false);

  const handleSelect = option => {
    if (game.currentAction === "bid") {
      setSelected(selected === option ? null : option);
    }
  };

  const isDisabled = option => {
    const {
      _clientPlayerIndex,
      currentPlayer,
      currentRound,
      players,
      gameHands,
    } = game;

    const hand = gameHands[currentRound];

    const roundLastPlayer =
      (currentRound + players.length - 1) % players.length;

    const isLastPlayer = currentPlayer === roundLastPlayer;

    const roundTotalBid = players
      .map(player => (player.bid ? player.bid : 0))
      .reduce((acc, cur) => {
        return acc + cur;
      });

    const bidNotAllowed = hand - roundTotalBid;

    return (
      (isLastPlayer && option === bidNotAllowed) ||
      _clientPlayerIndex !== currentPlayer
    );
  };

  const handleBid = option => {
    setSelected(null);
    setBid(true);
    onBid(option);
  };

  const hand = game.gameHands[game.currentRound];
  const hisTurn = game._clientPlayerIndex === game.currentPlayer;
  const options = Array.from(new Array(hand + 1), (val, index) => index);

  return (
    <div className="c-board">
      <ul className="c-bids o-list-inline">
        {bid
          ? null
          : options.map(option => (
              <li
                className={`o-list-inline__item c-bid-selectable ${
                  option === selected ? " is-selected " : ""
                }`}
                key={option}
              >
                <button
                  className={`c-bid`}
                  onClick={() => handleSelect(option)}
                >
                  <span>{option}</span>
                </button>

                {hisTurn ? (
                  isDisabled(option) ? (
                    <div className="c-easter-egg">
                      <span role="img" aria-label="shit">
                        💩
                      </span>
                      <br />
                      you can't
                      <br />
                      bid {option}
                    </div>
                  ) : (
                    <button
                      className="c-button"
                      onClick={() => handleBid(option)}
                    >
                      bid
                    </button>
                  )
                ) : (
                  <div className="c-easter-egg">
                    <span role="img" aria-label="clown">
                      🤡
                    </span>
                    <br />
                    wait for
                    <br />
                    your turn
                  </div>
                )}
              </li>
            ))}
      </ul>
    </div>
  );
};

export default Bid;
