import React from "react";
// import Draggable from "react-draggable";

import c from "../images/c.svg";
import d from "../images/d.svg";
import s from "../images/s.svg";
import h from "../images/h.svg";

const imgMap = {
  c: c,
  d: d,
  s: s,
  h: h,
};

const Card = ({
  card,
  atu,
  firstDrop,
  cards,
  index,
  selected,
  hisTurn,
  onDrop,
  onSelect,
}) => {
  // const [position, setPosition] = useState({ x: 0, y: 0 });

  const isDisabled = (() => {
    if (!firstDrop) {
      return false;
    }

    const atuSuite = atu ? atu.slice(0, 1) : null;
    const firstDropSuite = firstDrop ? firstDrop.name.slice(0, 1) : null;
    const cardSuite = card.slice(0, 1);

    const hasSomeFirstSuite = cards.some(
      card => card.slice(0, 1) === firstDropSuite
    );
    const hasSomeAtuSuite = cards.some(card => card.slice(0, 1) === atuSuite);

    if (cardSuite === firstDropSuite) {
      return false;
    }

    if (hasSomeFirstSuite) {
      return true;
    }

    if (cardSuite === atuSuite) {
      return false;
    }

    if (hasSomeAtuSuite) {
      return true;
    }

    return false;
  })();

  // const handleStopDrag = (e, position) => {
  //   if (position.y > 60 && !isDisabled) {
  //     setPosition({ x: 0, y: 200 });
  //     setTimeout(() => {
  //       onDrop(index);
  //     }, 100); // the time it takes to animate
  //   }
  // };

  const isSelected = card === selected;
  const suite = card.slice(0, 1).toLowerCase();

  return (
    <li
      key={card}
      className={`o-list-inline__item c-card-selectable ${
        isSelected ? "is-selected" : ""
      }`}
    >
      {/*<Draggable*/}
      {/*  axis={"y"}*/}
      {/*  bounds={{ top: 0 }}*/}
      {/*  onStop={handleStopDrag}*/}
      {/*  position={position}*/}
      {/*  disabled={!hisTurn}*/}
      {/*>*/}
      <button
        className={`c-card c-card--${suite}`}
        onClick={() => onSelect(card)}
      >
        <span>{card.slice(1)}</span>
        <img src={imgMap[suite]} alt="" />
      </button>
      {/*</Draggable>*/}

      {hisTurn ? (
        isDisabled ? (
          <div className="c-easter-egg">
            <span role="img" aria-label="ups">
              🤭
            </span>
            <br />
            can't drop
            <br />
            this one
          </div>
        ) : (
          <button className="c-button" onClick={() => onDrop(index)}>
            drop
          </button>
        )
      ) : (
        <div className="c-easter-egg">
          <span role="img" aria-label="clown">
            🤡
          </span>
          <br />
          wait for
          <br />
          your turn
        </div>
      )}
    </li>
  );
};

export default Card;
