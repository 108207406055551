import React from "react";

export default function Stats({ game }) {
  if (!game?.gameStarted) {
    return (
      <div className="c-stats">
        <div className="c-stats__item"></div>
      </div>
    );
  }

  const { currentRound, gameHands, currentAction } = game;
  const roundCards = gameHands[currentRound];
  return (
    <div className="c-stats">
      <div className="c-stats__item">
        <span className="c-stats__label">Action:</span> {currentAction}
      </div>

      <div className="c-stats__item u-text-center">
        <span className="c-stats__label">Round:</span> {currentRound + 1}
      </div>
      <div className="c-stats__item u-text-right">
        <span className="c-stats__label">Cards dealt:</span> {roundCards}
      </div>
    </div>
  );
}
