import React from "react";

const Loading = () => {
  return (
    <div className="o-page">
      <div className="o-page__content o-page__content--center u-ph">
        <div className="u-text-center">
          <div className="u-fs-64">
            <span role="img" aria-label="rolling">
              🙃
            </span>
          </div>
          <h1 className="p-loading__message">
            Loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Loading;
