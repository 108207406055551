import React from "react";

import { getPlayerName } from "../models/game";

import c from "../images/c.svg";
import d from "../images/d.svg";
import s from "../images/s.svg";
import h from "../images/h.svg";

const imgMap = {
  c: c,
  d: d,
  s: s,
  h: h,
};

const getSuite = card => {
  return card.name.slice(0, 1).toLowerCase();
};

export default function Dropped({ cards, players, users }) {
  return (
    <div className="c-board">
      <ul className="c-cards o-list-inline">
        {cards.map(card => (
          <li key={card.name} className="o-list-inline__item">
            <div
              className={`c-card--${getSuite(
                card
              )} c-card c-card--small zoomIn`}
            >
              <div className="c-card__label">{getPlayerName(users, players[card.playerIndex].id)}</div>
              <span>{card.name.slice(1)}</span>
              <img src={imgMap[getSuite(card)]} alt="" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
