import React, { useState, useRef } from "react";
import { db } from "../firebase";
import { navigate } from "@reach/router";

import { useClickOutside } from "../utils/hooks";
import IconPlus from "../images/plus.inline.svg";
import IconClose from "../images/cross.inline.svg";

const CreateRoom = ({ user }) => {
  const [popperState, setPopperState] = useState("CLOSED");
  const [roomName, setRoomName] = useState("");

  const popperRef = useRef(null);
  useClickOutside(popperRef, popperState, setPopperState);

  const handleChangeRoomInput = event => {
    setRoomName(event.target.value);
  };

  const handleCreateRoom = async event => {
    event.preventDefault();
    const roomRef = await db.collection("rooms").add({
      name: roomName,
      ownerId: user.uid,
    });

    navigate(`/app/room/${roomRef.id}`);
  };

  return (
    <>
      {popperState === "CLOSED" && (
        <button
          type="button"
          className="c-create-room__trigger"
          onClick={() => setPopperState("OPEN")}
        >
          <IconPlus />
        </button>
      )}

      {popperState === "OPEN" && (
        <div className="c-create-room" ref={popperRef}>
          <button
            type="button"
            className="c-create-room__close"
            onClick={() => setPopperState("CLOSED")}
          >
            <IconClose />
          </button>

          <form onSubmit={handleCreateRoom}>
            <label className="c-label" htmlFor="roomName">
              Room name
            </label>
            <input
              // autoFocus
              type="text"
              className="c-input u-mb"
              id="roomName"
              value={roomName}
              onChange={handleChangeRoomInput}
              placeholder={`e.g. ${user.displayName}'s room`}
            />
            <button type="submit" className="c-button u-1/1">
              create room
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default CreateRoom;
