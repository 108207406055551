import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import { db } from "../firebase";
import UserContext from "../context/auth";
import SEO from "../components/seo";
import CreateRoom from "./createRoom";
import { useClickOutside } from "../utils/hooks";
import IconMore from "../images/more.inline.svg";

const Rooms = () => {
  const [popperState, setPopperState] = useState("CLOSED");
  const [rooms, setRooms] = useState([]);

  const userManager = useContext(UserContext);
  const user = userManager.user;
  const userId = user?.uid || null;

  const popperRef = useRef(null);
  useClickOutside(popperRef, popperState, setPopperState);

  useEffect(() => {
    if (userId) {
      db.collection("rooms")
        .where("usersIds", "array-contains", userId)
        .onSnapshot(querySnapshot => {
          const newRooms = [];
          querySnapshot.forEach(roomDoc => {
            newRooms.push({
              ...roomDoc.data(),
              id: roomDoc.id,
            });
          });
          setRooms(newRooms);
        });
    }
  }, [userId]);

  return (
    <div className="o-page">
      <SEO title="Rooms" />

      <header className="c-app-header">
        <div className="c-app-header__action"></div>
        <div className="c-app-header__main u-text-center">Rooms</div>
        <div className="c-app-header__action">
          <button
            className="c-app-header__button c-app-header__button--menu"
            onClick={() => setPopperState("OPEN")}
          >
            <IconMore className="c-app-header__button-icon" />
          </button>
          <div
            className={`c-app-header__menu ${
              popperState === "OPEN" ? "is-open" : ""
            }`}
            ref={popperRef}
          >
            <ul className="o-list-vertical">
              <li className="o-list-vertical__item">
                <button onClick={userManager.signOut} className="c-menu__link">
                  Sign out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div className="o-page__content o-container p-rooms__content">
        {rooms.length ? (
          <ul className="o-list-vertical u-mt-15">
            {rooms.map(room => (
              <li key={room.id} className="o-list-vertical__item">
                <Link to={`/app/room/${room.id}`} className="c-room">
                  <div className="c-room__name">{room.name}</div>
                  <div className="c-room__players">
                    {room.usersIds.length} player
                    {room.usersIds.length > 1 ? "s" : ""}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <div className="p-rooms__empty u-text-center">
            <div className="p-rooms__empty-sign u-fs-64 u-text-center">
              ¯\_(ツ)_/¯
            </div>
            <h1 className="u-mb">It's empty in here!</h1>
            <p className="u-mb-30">Go ahead and create a room.</p>
          </div>
        )}

        <CreateRoom user={user}></CreateRoom>
      </div>
    </div>
  );
};

export default Rooms;
