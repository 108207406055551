import React, { useContext } from "react";

import Login from "./login";
import Loading from "./loading";
import UserContext from "../context/auth";

const PrivateRoute = props => {
  const { user, userStatus } = useContext(UserContext);

  let { as: Comp, ...rest } = props;

  if (userStatus && userStatus !== "LOADING") {
    return user ? <Comp {...rest} /> : <Login />;
  }

  return <Loading />;
};

export default PrivateRoute;
