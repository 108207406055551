import React from "react";

import c from "../images/c.svg";
import d from "../images/d.svg";
import s from "../images/s.svg";
import h from "../images/h.svg";

const imgMap = {
  c: c,
  d: d,
  s: s,
  h: h,
};

export default function Atu({ atu }) {
  if(!atu) {
    return null;
  }
  const suite = atu.slice(0, 1).toLowerCase();
  return (
    <div className="c-atu">
      <div className={`c-card c-card--${suite} c-card--small`}>
        <div className="c-card__label">TRUMP</div>
        <span>{atu.slice(1)}</span>
        <img src={imgMap[suite]} alt="" />
      </div>
    </div>
  );
}
