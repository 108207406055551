import React, { useState, useEffect } from "react";

import IDeck from "../models/deck";
import { getPlayerName } from "../models/game";

const getWinnerCard = (droppedCards, atu) => {
  const droppedCardsSorted = IDeck.sortCards(droppedCards);

  let newWinnerCard = null;

  if (atu) {
    // check if there is a card with the same suite as atu
    newWinnerCard = droppedCardsSorted.find(card => {
      return card.name.slice(0, 1) === atu.slice(0, 1);
    });
  }

  if (!newWinnerCard) {
    // no card with the same suit as atu was found
    // find the highest card with the same suite as the first dropped card
    newWinnerCard = droppedCardsSorted.find(card => {
      return card.name.slice(0, 1) === droppedCards[0].name.slice(0, 1);
    });
  }

  return newWinnerCard;
};

export default function Continue({ game, users, onContinue }) {
  const [continues, setContinues] = useState(false);

  const { droppedCards, atu, players } = game;

  useEffect(() => {}, [droppedCards, atu]);

  const handleClick = () => {
    setContinues(true);
    onContinue();
  };

  const getWinner = () => {
    const winnerCard = getWinnerCard(droppedCards, atu);
    if (winnerCard) {
      return getPlayerName(users, players[winnerCard.playerIndex].id);
    }

    return null;
  };

  return (
    <>
      <div className="c-game__footer-message">{getWinner()} won the hand</div>
      <div className="c-game__footer-button-container">
        {continues ? (
          <div className="c-continue__waiting">
            Waiting for the others
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        ) : (
          <button type="button" className="c-button u-1/1" onClick={handleClick}>
            continue
          </button>
        )}
      </div>

    </>
  );
}
