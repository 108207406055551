import React, { useContext, useState } from "react";
import UserContext from "../context/auth";

import Layout from "./layout";
import IconFacebook from "../images/facebook.inline.svg";

const Login = () => {
  const [username, setUsername] = useState(null);
  const userManager = useContext(UserContext);

  const handleChangeInput = event => {
    setUsername(event.target.value);
  };

  const handleGuestSignIn = event => {
    event.preventDefault();
    userManager.signIn(username);
  };

  const handleClickFacebook = () => {
    userManager.singInFacebook();
  };

  return (
    <Layout>
      <main className="o-page__content o-page__content--center o-container">
        <div className="u-text-center u-mb-30">
          <div className="u-fs-64">
            <span role="img" aria-label="hand">
              👋
            </span>
          </div>
          <h1>Hello!</h1>
        </div>

        <div className="p-login__box">
          <form onSubmit={handleGuestSignIn}>
            <label className="c-label" htmlFor="userName">
              What is your name?
            </label>
            <input
              type="text"
              id="userName"
              className="c-input u-mb"
              required
              onChange={handleChangeInput}
            />
            <button type="submit" className="c-button u-1/1">
              Play as guest
            </button>
          </form>

          <div className="p-login__separator">
            <span>or</span>
          </div>

          <button
            type="button"
            className="c-button c-button--facebook u-1/1"
            onClick={handleClickFacebook}
          >
            <IconFacebook />
            <span>continue with facebook</span>
          </button>
        </div>
      </main>
    </Layout>
  );
};

export default Login;
