import React from "react";

import { getPlayerName } from "../models/game";

const Finished = ({ winnersIndexes, players, users }) => {
  const winnerNames = winnersIndexes.map(winnerIndex => {
    return getPlayerName(users, players[winnerIndex].id);
  });
  return (
    <div className="u-1/1 u-ph">
      <p className="u-text-center">
        Game finished!
        <br />
        The winner {winnerNames.length === 1 ? "is" : "are"}:{" "}
        {winnerNames.join(", ")}
      </p>
    </div>
  );
};

export default Finished;
