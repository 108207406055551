import React from "react";

const RoomInfo = () => {
  return (
    <div className="u-1/1 u-ph">
      <p className="u-text-center">
        Copy the room's link and send it to your friends.
        <br />
        When everybody is in the room start the game.
      </p>
    </div>
  );
};

export default RoomInfo;
