import React from "react";

function RestartGame({ onRestartGame }) {
  return (
    <div className="u-ph u-pv">
      <button
        type="button"
        onClick={onRestartGame}
        className="c-button c-button--large u-1/1"
      >
        Restart game
      </button>
    </div>
  );
}

export default RestartGame;
