import React, { Component } from "react";
import Card from "./Card";

class Cards extends Component {
  state = {
    selected: null,
  };

  handleSelect = card => {
    if (this.props.currentAction === "drop") {
      this.setState(prevState => {
        return {
          selected: prevState.selected === card ? null : card,
        };
      });
    }
  };

  handleDrop = index => {
    this.setState({ selected: null }, () => {
      this.props.onDrop(index);
    });
  };

  render() {
    // do not send this.props.onDrop to <Card>
    const { cards, onDrop, ...rest } = this.props;

    if (cards) {
      return (
        <div className="c-player-cards">
          <ul className="c-cards o-list-inline">
            {cards.map((card, index) => (
              <Card
                key={card}
                card={card}
                cards={cards}
                index={index}
                selected={this.state.selected}
                onSelect={this.handleSelect}
                onDrop={this.handleDrop}
                {...rest}
              />
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Cards;
